/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type Device = {
  __typename: "Device",
  siteid: string,
  deviceid: string,
  hw_id?: string | null,
};

export type Patient = {
  __typename: "Patient",
  siteid: string,
  patientid: string,
  name: string,
};

export type Site = {
  __typename: "Site",
  siteid: string,
  name: string,
  devices?:  Array<Device > | null,
  patients?:  Array<Patient > | null,
  studies?:  Array<Study > | null,
};

export type Study = {
  __typename: "Study",
  siteid: string,
  studyid: string,
  status: string,
  name: string,
  identifier?: string | null,
  activitytypes?:  Array<ActivityType > | null,
  formtypes?:  Array<FormType > | null,
  subjects?:  Array<Subject > | null,
};

export type ActivityType = {
  __typename: "ActivityType",
  activitytypeid: string,
  description: string,
  studyid: string,
  study?: Study | null,
};

export type FormType = {
  __typename: "FormType",
  studyid: string,
  formtypeid: string,
  identifier: string,
  fields?:  Array<FormTypeField > | null,
};

export type FormTypeField = {
  __typename: "FormTypeField",
  name: string,
  type: string,
  required: boolean,
  length?: number | null,
  pattern?: string | null,
};

export type Subject = {
  __typename: "Subject",
  siteid: string,
  studyid: string,
  subjectid: string,
  identifier?: string | null,
  status: SubjectStatusType,
  forms?:  Array<Form > | null,
  activities?:  Array<Activity > | null,
  devices?:  Array<SubjectDevice > | null,
  snapshot?: string | null,
};

export enum SubjectStatusType {
  CLOSED = "CLOSED",
  DELETED = "DELETED",
  OPEN = "OPEN",
}


export type Form = {
  __typename: "Form",
  subjectid: string,
  formtypeid: string,
  formtype?: FormType | null,
  fields?:  Array<KeyValuePair > | null,
};

export type KeyValuePair = {
  __typename: "KeyValuePair",
  key: string,
  value: string,
};

export type Activity = {
  __typename: "Activity",
  activitytypeid: string,
  subjectid: string,
  comment?: string | null,
  startTime: number,
  stopTime: number,
  activitytype?: ActivityType | null,
  referenceData?:  Array<ReferenceData > | null,
};

export type ReferenceData = {
  __typename: "ReferenceData",
  referencedataid: string,
  activitytypeid: string,
  siteid: string,
  subjectid: string,
  studyid: string,
  fields: string,
  filename: string,
  persistedObjectName: string,
  objectUrl: string,
  status: ReferenceDataStatusType,
};

export enum ReferenceDataStatusType {
  NEW = "NEW",
  UPLOADED = "UPLOADED",
}


export type SubjectDevice = {
  __typename: "SubjectDevice",
  deviceid: string,
  position: SubjectDevicePositionType,
};

export enum SubjectDevicePositionType {
  ARM = "ARM",
  CHEST = "CHEST",
}


export type SubjectInput = {
  siteid: string,
  studyid: string,
  subjectid: string,
  devices?: Array< SubjectDeviceInput > | null,
  status?: SubjectStatusType | null,
  snapshot?: string | null,
};

export type SubjectDeviceInput = {
  deviceid: string,
  position: SubjectDevicePositionType,
};

export type SubjectInputV2 = {
  siteid: string,
  studyid: string,
  identifier: string,
  devices?: Array< SubjectDeviceInput > | null,
  status?: SubjectStatusType | null,
  snapshot?: string | null,
};

export type SubjectUpdateInput = {
  siteid: string,
  studyid: string,
  subjectid: string,
  identifier?: string | null,
  devices?: Array< SubjectDeviceInput > | null,
  status?: SubjectStatusType | null,
  snapshot?: string | null,
};

export type FormTypeInput = {
  studyid: string,
  identifier: string,
  fields?: Array< FormTypeFieldInput > | null,
};

export type FormTypeFieldInput = {
  name: string,
  type: string,
  required: boolean,
  length?: number | null,
  pattern?: string | null,
};

export type FormInput = {
  subjectid: string,
  formtypeid: string,
  fields?: Array< KeyValuePairInput > | null,
};

export type KeyValuePairInput = {
  key: string,
  value: string,
};

export type ActivityInput = {
  activitytypeid: string,
  subjectid: string,
  comment?: string | null,
  startTime: number,
  stopTime: number,
};

export type ActivityTypeInput = {
  description: string,
  studyid: string,
};

export type ReferenceDataInput = {
  activitytypeid: string,
  siteid: string,
  studyid: string,
  filename: string,
  identifier?: string | null,
};

export type AddDeviceMutationVariables = {
  siteid: string,
  deviceid: string,
};

export type AddDeviceMutation = {
  addDevice?:  {
    __typename: "Device",
    siteid: string,
    deviceid: string,
    hw_id?: string | null,
  } | null,
};

export type UpdateDeviceMutationVariables = {
  siteid: string,
  deviceid: string,
};

export type UpdateDeviceMutation = {
  updateDevice?:  {
    __typename: "Device",
    siteid: string,
    deviceid: string,
    hw_id?: string | null,
  } | null,
};

export type RemoveDeviceMutationVariables = {
  siteid: string,
  deviceid: string,
};

export type RemoveDeviceMutation = {
  removeDevice?:  {
    __typename: "Device",
    siteid: string,
    deviceid: string,
    hw_id?: string | null,
  } | null,
};

export type AddPatientMutationVariables = {
  siteid: string,
  name: string,
};

export type AddPatientMutation = {
  addPatient?:  {
    __typename: "Patient",
    siteid: string,
    patientid: string,
    name: string,
  } | null,
};

export type UpdatePatientMutationVariables = {
  siteid: string,
  patientid: string,
  name: string,
};

export type UpdatePatientMutation = {
  updatePatient?:  {
    __typename: "Patient",
    siteid: string,
    patientid: string,
    name: string,
  } | null,
};

export type RemovePatientMutationVariables = {
  siteid: string,
  patientid: string,
};

export type RemovePatientMutation = {
  removePatient?:  {
    __typename: "Patient",
    siteid: string,
    patientid: string,
    name: string,
  } | null,
};

export type AddSiteMutationVariables = {
  siteid: string,
  name: string,
};

export type AddSiteMutation = {
  addSite?:  {
    __typename: "Site",
    siteid: string,
    name: string,
    devices?:  Array< {
      __typename: "Device",
      siteid: string,
      deviceid: string,
      hw_id?: string | null,
    } > | null,
    patients?:  Array< {
      __typename: "Patient",
      siteid: string,
      patientid: string,
      name: string,
    } > | null,
    studies?:  Array< {
      __typename: "Study",
      siteid: string,
      studyid: string,
      status: string,
      name: string,
      identifier?: string | null,
      activitytypes?:  Array< {
        __typename: "ActivityType",
        activitytypeid: string,
        description: string,
        studyid: string,
      } > | null,
      formtypes?:  Array< {
        __typename: "FormType",
        studyid: string,
        formtypeid: string,
        identifier: string,
      } > | null,
      subjects?:  Array< {
        __typename: "Subject",
        siteid: string,
        studyid: string,
        subjectid: string,
        identifier?: string | null,
        status: SubjectStatusType,
        snapshot?: string | null,
      } > | null,
    } > | null,
  } | null,
};

export type UpdateSiteMutationVariables = {
  siteid: string,
  name: string,
};

export type UpdateSiteMutation = {
  updateSite?:  {
    __typename: "Site",
    siteid: string,
    name: string,
    devices?:  Array< {
      __typename: "Device",
      siteid: string,
      deviceid: string,
      hw_id?: string | null,
    } > | null,
    patients?:  Array< {
      __typename: "Patient",
      siteid: string,
      patientid: string,
      name: string,
    } > | null,
    studies?:  Array< {
      __typename: "Study",
      siteid: string,
      studyid: string,
      status: string,
      name: string,
      identifier?: string | null,
      activitytypes?:  Array< {
        __typename: "ActivityType",
        activitytypeid: string,
        description: string,
        studyid: string,
      } > | null,
      formtypes?:  Array< {
        __typename: "FormType",
        studyid: string,
        formtypeid: string,
        identifier: string,
      } > | null,
      subjects?:  Array< {
        __typename: "Subject",
        siteid: string,
        studyid: string,
        subjectid: string,
        identifier?: string | null,
        status: SubjectStatusType,
        snapshot?: string | null,
      } > | null,
    } > | null,
  } | null,
};

export type RemoveSiteMutationVariables = {
  siteid: string,
};

export type RemoveSiteMutation = {
  removeSite?:  {
    __typename: "Site",
    siteid: string,
    name: string,
    devices?:  Array< {
      __typename: "Device",
      siteid: string,
      deviceid: string,
      hw_id?: string | null,
    } > | null,
    patients?:  Array< {
      __typename: "Patient",
      siteid: string,
      patientid: string,
      name: string,
    } > | null,
    studies?:  Array< {
      __typename: "Study",
      siteid: string,
      studyid: string,
      status: string,
      name: string,
      identifier?: string | null,
      activitytypes?:  Array< {
        __typename: "ActivityType",
        activitytypeid: string,
        description: string,
        studyid: string,
      } > | null,
      formtypes?:  Array< {
        __typename: "FormType",
        studyid: string,
        formtypeid: string,
        identifier: string,
      } > | null,
      subjects?:  Array< {
        __typename: "Subject",
        siteid: string,
        studyid: string,
        subjectid: string,
        identifier?: string | null,
        status: SubjectStatusType,
        snapshot?: string | null,
      } > | null,
    } > | null,
  } | null,
};

export type AddStudyMutationVariables = {
  siteid: string,
  name: string,
};

export type AddStudyMutation = {
  addStudy?:  {
    __typename: "Study",
    siteid: string,
    studyid: string,
    status: string,
    name: string,
    identifier?: string | null,
    activitytypes?:  Array< {
      __typename: "ActivityType",
      activitytypeid: string,
      description: string,
      studyid: string,
      study?:  {
        __typename: "Study",
        siteid: string,
        studyid: string,
        status: string,
        name: string,
        identifier?: string | null,
      } | null,
    } > | null,
    formtypes?:  Array< {
      __typename: "FormType",
      studyid: string,
      formtypeid: string,
      identifier: string,
      fields?:  Array< {
        __typename: "FormTypeField",
        name: string,
        type: string,
        required: boolean,
        length?: number | null,
        pattern?: string | null,
      } > | null,
    } > | null,
    subjects?:  Array< {
      __typename: "Subject",
      siteid: string,
      studyid: string,
      subjectid: string,
      identifier?: string | null,
      status: SubjectStatusType,
      forms?:  Array< {
        __typename: "Form",
        subjectid: string,
        formtypeid: string,
      } > | null,
      activities?:  Array< {
        __typename: "Activity",
        activitytypeid: string,
        subjectid: string,
        comment?: string | null,
        startTime: number,
        stopTime: number,
      } > | null,
      devices?:  Array< {
        __typename: "SubjectDevice",
        deviceid: string,
        position: SubjectDevicePositionType,
      } > | null,
      snapshot?: string | null,
    } > | null,
  } | null,
};

export type UpdateStudyMutationVariables = {
  siteid: string,
  studyid: string,
  name?: string | null,
  status?: string | null,
};

export type UpdateStudyMutation = {
  updateStudy?:  {
    __typename: "Study",
    siteid: string,
    studyid: string,
    status: string,
    name: string,
    identifier?: string | null,
    activitytypes?:  Array< {
      __typename: "ActivityType",
      activitytypeid: string,
      description: string,
      studyid: string,
      study?:  {
        __typename: "Study",
        siteid: string,
        studyid: string,
        status: string,
        name: string,
        identifier?: string | null,
      } | null,
    } > | null,
    formtypes?:  Array< {
      __typename: "FormType",
      studyid: string,
      formtypeid: string,
      identifier: string,
      fields?:  Array< {
        __typename: "FormTypeField",
        name: string,
        type: string,
        required: boolean,
        length?: number | null,
        pattern?: string | null,
      } > | null,
    } > | null,
    subjects?:  Array< {
      __typename: "Subject",
      siteid: string,
      studyid: string,
      subjectid: string,
      identifier?: string | null,
      status: SubjectStatusType,
      forms?:  Array< {
        __typename: "Form",
        subjectid: string,
        formtypeid: string,
      } > | null,
      activities?:  Array< {
        __typename: "Activity",
        activitytypeid: string,
        subjectid: string,
        comment?: string | null,
        startTime: number,
        stopTime: number,
      } > | null,
      devices?:  Array< {
        __typename: "SubjectDevice",
        deviceid: string,
        position: SubjectDevicePositionType,
      } > | null,
      snapshot?: string | null,
    } > | null,
  } | null,
};

export type RemoveStudyMutationVariables = {
  siteid: string,
  studyid: string,
};

export type RemoveStudyMutation = {
  removeStudy?:  {
    __typename: "Study",
    siteid: string,
    studyid: string,
    status: string,
    name: string,
    identifier?: string | null,
    activitytypes?:  Array< {
      __typename: "ActivityType",
      activitytypeid: string,
      description: string,
      studyid: string,
      study?:  {
        __typename: "Study",
        siteid: string,
        studyid: string,
        status: string,
        name: string,
        identifier?: string | null,
      } | null,
    } > | null,
    formtypes?:  Array< {
      __typename: "FormType",
      studyid: string,
      formtypeid: string,
      identifier: string,
      fields?:  Array< {
        __typename: "FormTypeField",
        name: string,
        type: string,
        required: boolean,
        length?: number | null,
        pattern?: string | null,
      } > | null,
    } > | null,
    subjects?:  Array< {
      __typename: "Subject",
      siteid: string,
      studyid: string,
      subjectid: string,
      identifier?: string | null,
      status: SubjectStatusType,
      forms?:  Array< {
        __typename: "Form",
        subjectid: string,
        formtypeid: string,
      } > | null,
      activities?:  Array< {
        __typename: "Activity",
        activitytypeid: string,
        subjectid: string,
        comment?: string | null,
        startTime: number,
        stopTime: number,
      } > | null,
      devices?:  Array< {
        __typename: "SubjectDevice",
        deviceid: string,
        position: SubjectDevicePositionType,
      } > | null,
      snapshot?: string | null,
    } > | null,
  } | null,
};

export type AddSubjectMutationVariables = {
  input: SubjectInput,
};

export type AddSubjectMutation = {
  addSubject?:  {
    __typename: "Subject",
    siteid: string,
    studyid: string,
    subjectid: string,
    identifier?: string | null,
    status: SubjectStatusType,
    forms?:  Array< {
      __typename: "Form",
      subjectid: string,
      formtypeid: string,
      formtype?:  {
        __typename: "FormType",
        studyid: string,
        formtypeid: string,
        identifier: string,
      } | null,
      fields?:  Array< {
        __typename: "KeyValuePair",
        key: string,
        value: string,
      } > | null,
    } > | null,
    activities?:  Array< {
      __typename: "Activity",
      activitytypeid: string,
      subjectid: string,
      comment?: string | null,
      startTime: number,
      stopTime: number,
      activitytype?:  {
        __typename: "ActivityType",
        activitytypeid: string,
        description: string,
        studyid: string,
      } | null,
      referenceData?:  Array< {
        __typename: "ReferenceData",
        referencedataid: string,
        activitytypeid: string,
        siteid: string,
        subjectid: string,
        studyid: string,
        fields: string,
        filename: string,
        persistedObjectName: string,
        objectUrl: string,
        status: ReferenceDataStatusType,
      } > | null,
    } > | null,
    devices?:  Array< {
      __typename: "SubjectDevice",
      deviceid: string,
      position: SubjectDevicePositionType,
    } > | null,
    snapshot?: string | null,
  } | null,
};

export type AddSubjectV2MutationVariables = {
  input: SubjectInputV2,
};

export type AddSubjectV2Mutation = {
  addSubjectV2?:  {
    __typename: "Subject",
    siteid: string,
    studyid: string,
    subjectid: string,
    identifier?: string | null,
    status: SubjectStatusType,
    forms?:  Array< {
      __typename: "Form",
      subjectid: string,
      formtypeid: string,
      formtype?:  {
        __typename: "FormType",
        studyid: string,
        formtypeid: string,
        identifier: string,
      } | null,
      fields?:  Array< {
        __typename: "KeyValuePair",
        key: string,
        value: string,
      } > | null,
    } > | null,
    activities?:  Array< {
      __typename: "Activity",
      activitytypeid: string,
      subjectid: string,
      comment?: string | null,
      startTime: number,
      stopTime: number,
      activitytype?:  {
        __typename: "ActivityType",
        activitytypeid: string,
        description: string,
        studyid: string,
      } | null,
      referenceData?:  Array< {
        __typename: "ReferenceData",
        referencedataid: string,
        activitytypeid: string,
        siteid: string,
        subjectid: string,
        studyid: string,
        fields: string,
        filename: string,
        persistedObjectName: string,
        objectUrl: string,
        status: ReferenceDataStatusType,
      } > | null,
    } > | null,
    devices?:  Array< {
      __typename: "SubjectDevice",
      deviceid: string,
      position: SubjectDevicePositionType,
    } > | null,
    snapshot?: string | null,
  } | null,
};

export type UpdateSubjectMutationVariables = {
  input: SubjectUpdateInput,
};

export type UpdateSubjectMutation = {
  updateSubject?:  {
    __typename: "Subject",
    siteid: string,
    studyid: string,
    subjectid: string,
    identifier?: string | null,
    status: SubjectStatusType,
    forms?:  Array< {
      __typename: "Form",
      subjectid: string,
      formtypeid: string,
      formtype?:  {
        __typename: "FormType",
        studyid: string,
        formtypeid: string,
        identifier: string,
      } | null,
      fields?:  Array< {
        __typename: "KeyValuePair",
        key: string,
        value: string,
      } > | null,
    } > | null,
    activities?:  Array< {
      __typename: "Activity",
      activitytypeid: string,
      subjectid: string,
      comment?: string | null,
      startTime: number,
      stopTime: number,
      activitytype?:  {
        __typename: "ActivityType",
        activitytypeid: string,
        description: string,
        studyid: string,
      } | null,
      referenceData?:  Array< {
        __typename: "ReferenceData",
        referencedataid: string,
        activitytypeid: string,
        siteid: string,
        subjectid: string,
        studyid: string,
        fields: string,
        filename: string,
        persistedObjectName: string,
        objectUrl: string,
        status: ReferenceDataStatusType,
      } > | null,
    } > | null,
    devices?:  Array< {
      __typename: "SubjectDevice",
      deviceid: string,
      position: SubjectDevicePositionType,
    } > | null,
    snapshot?: string | null,
  } | null,
};

export type RemoveSubjectMutationVariables = {
  studyid: string,
  subjectid: string,
};

export type RemoveSubjectMutation = {
  removeSubject?:  {
    __typename: "Subject",
    siteid: string,
    studyid: string,
    subjectid: string,
    identifier?: string | null,
    status: SubjectStatusType,
    forms?:  Array< {
      __typename: "Form",
      subjectid: string,
      formtypeid: string,
      formtype?:  {
        __typename: "FormType",
        studyid: string,
        formtypeid: string,
        identifier: string,
      } | null,
      fields?:  Array< {
        __typename: "KeyValuePair",
        key: string,
        value: string,
      } > | null,
    } > | null,
    activities?:  Array< {
      __typename: "Activity",
      activitytypeid: string,
      subjectid: string,
      comment?: string | null,
      startTime: number,
      stopTime: number,
      activitytype?:  {
        __typename: "ActivityType",
        activitytypeid: string,
        description: string,
        studyid: string,
      } | null,
      referenceData?:  Array< {
        __typename: "ReferenceData",
        referencedataid: string,
        activitytypeid: string,
        siteid: string,
        subjectid: string,
        studyid: string,
        fields: string,
        filename: string,
        persistedObjectName: string,
        objectUrl: string,
        status: ReferenceDataStatusType,
      } > | null,
    } > | null,
    devices?:  Array< {
      __typename: "SubjectDevice",
      deviceid: string,
      position: SubjectDevicePositionType,
    } > | null,
    snapshot?: string | null,
  } | null,
};

export type AddFormTypeMutationVariables = {
  input: FormTypeInput,
};

export type AddFormTypeMutation = {
  addFormType?:  {
    __typename: "FormType",
    studyid: string,
    formtypeid: string,
    identifier: string,
    fields?:  Array< {
      __typename: "FormTypeField",
      name: string,
      type: string,
      required: boolean,
      length?: number | null,
      pattern?: string | null,
    } > | null,
  } | null,
};

export type UpdateFormTypeMutationVariables = {
  formtypeid: string,
  input?: FormTypeInput | null,
};

export type UpdateFormTypeMutation = {
  updateFormType?:  {
    __typename: "FormType",
    studyid: string,
    formtypeid: string,
    identifier: string,
    fields?:  Array< {
      __typename: "FormTypeField",
      name: string,
      type: string,
      required: boolean,
      length?: number | null,
      pattern?: string | null,
    } > | null,
  } | null,
};

export type RemoveFormTypeMutationVariables = {
  studyid: string,
  formtypeid: string,
};

export type RemoveFormTypeMutation = {
  removeFormType?:  {
    __typename: "FormType",
    studyid: string,
    formtypeid: string,
    identifier: string,
    fields?:  Array< {
      __typename: "FormTypeField",
      name: string,
      type: string,
      required: boolean,
      length?: number | null,
      pattern?: string | null,
    } > | null,
  } | null,
};

export type AddFormMutationVariables = {
  input: FormInput,
};

export type AddFormMutation = {
  addForm?:  {
    __typename: "Form",
    subjectid: string,
    formtypeid: string,
    formtype?:  {
      __typename: "FormType",
      studyid: string,
      formtypeid: string,
      identifier: string,
      fields?:  Array< {
        __typename: "FormTypeField",
        name: string,
        type: string,
        required: boolean,
        length?: number | null,
        pattern?: string | null,
      } > | null,
    } | null,
    fields?:  Array< {
      __typename: "KeyValuePair",
      key: string,
      value: string,
    } > | null,
  } | null,
};

export type UpdateFormMutationVariables = {
  input: FormInput,
};

export type UpdateFormMutation = {
  updateForm?:  {
    __typename: "Form",
    subjectid: string,
    formtypeid: string,
    formtype?:  {
      __typename: "FormType",
      studyid: string,
      formtypeid: string,
      identifier: string,
      fields?:  Array< {
        __typename: "FormTypeField",
        name: string,
        type: string,
        required: boolean,
        length?: number | null,
        pattern?: string | null,
      } > | null,
    } | null,
    fields?:  Array< {
      __typename: "KeyValuePair",
      key: string,
      value: string,
    } > | null,
  } | null,
};

export type RemoveFormMutationVariables = {
  subjectid: string,
  formtypeid: string,
};

export type RemoveFormMutation = {
  removeForm?:  {
    __typename: "Form",
    subjectid: string,
    formtypeid: string,
    formtype?:  {
      __typename: "FormType",
      studyid: string,
      formtypeid: string,
      identifier: string,
      fields?:  Array< {
        __typename: "FormTypeField",
        name: string,
        type: string,
        required: boolean,
        length?: number | null,
        pattern?: string | null,
      } > | null,
    } | null,
    fields?:  Array< {
      __typename: "KeyValuePair",
      key: string,
      value: string,
    } > | null,
  } | null,
};

export type AddActivityMutationVariables = {
  input: ActivityInput,
};

export type AddActivityMutation = {
  addActivity?:  {
    __typename: "Activity",
    activitytypeid: string,
    subjectid: string,
    comment?: string | null,
    startTime: number,
    stopTime: number,
    activitytype?:  {
      __typename: "ActivityType",
      activitytypeid: string,
      description: string,
      studyid: string,
      study?:  {
        __typename: "Study",
        siteid: string,
        studyid: string,
        status: string,
        name: string,
        identifier?: string | null,
      } | null,
    } | null,
    referenceData?:  Array< {
      __typename: "ReferenceData",
      referencedataid: string,
      activitytypeid: string,
      siteid: string,
      subjectid: string,
      studyid: string,
      fields: string,
      filename: string,
      persistedObjectName: string,
      objectUrl: string,
      status: ReferenceDataStatusType,
    } > | null,
  } | null,
};

export type UpdateActivityMutationVariables = {
  input: ActivityInput,
};

export type UpdateActivityMutation = {
  updateActivity?:  {
    __typename: "Activity",
    activitytypeid: string,
    subjectid: string,
    comment?: string | null,
    startTime: number,
    stopTime: number,
    activitytype?:  {
      __typename: "ActivityType",
      activitytypeid: string,
      description: string,
      studyid: string,
      study?:  {
        __typename: "Study",
        siteid: string,
        studyid: string,
        status: string,
        name: string,
        identifier?: string | null,
      } | null,
    } | null,
    referenceData?:  Array< {
      __typename: "ReferenceData",
      referencedataid: string,
      activitytypeid: string,
      siteid: string,
      subjectid: string,
      studyid: string,
      fields: string,
      filename: string,
      persistedObjectName: string,
      objectUrl: string,
      status: ReferenceDataStatusType,
    } > | null,
  } | null,
};

export type RemoveActivityMutationVariables = {
  subjectid: string,
  activitytypeid: string,
};

export type RemoveActivityMutation = {
  removeActivity?:  {
    __typename: "Activity",
    activitytypeid: string,
    subjectid: string,
    comment?: string | null,
    startTime: number,
    stopTime: number,
    activitytype?:  {
      __typename: "ActivityType",
      activitytypeid: string,
      description: string,
      studyid: string,
      study?:  {
        __typename: "Study",
        siteid: string,
        studyid: string,
        status: string,
        name: string,
        identifier?: string | null,
      } | null,
    } | null,
    referenceData?:  Array< {
      __typename: "ReferenceData",
      referencedataid: string,
      activitytypeid: string,
      siteid: string,
      subjectid: string,
      studyid: string,
      fields: string,
      filename: string,
      persistedObjectName: string,
      objectUrl: string,
      status: ReferenceDataStatusType,
    } > | null,
  } | null,
};

export type AddActivityTypeMutationVariables = {
  input: ActivityTypeInput,
};

export type AddActivityTypeMutation = {
  addActivityType?:  {
    __typename: "ActivityType",
    activitytypeid: string,
    description: string,
    studyid: string,
    study?:  {
      __typename: "Study",
      siteid: string,
      studyid: string,
      status: string,
      name: string,
      identifier?: string | null,
      activitytypes?:  Array< {
        __typename: "ActivityType",
        activitytypeid: string,
        description: string,
        studyid: string,
      } > | null,
      formtypes?:  Array< {
        __typename: "FormType",
        studyid: string,
        formtypeid: string,
        identifier: string,
      } > | null,
      subjects?:  Array< {
        __typename: "Subject",
        siteid: string,
        studyid: string,
        subjectid: string,
        identifier?: string | null,
        status: SubjectStatusType,
        snapshot?: string | null,
      } > | null,
    } | null,
  } | null,
};

export type UpdateActivityTypeMutationVariables = {
  activitytypeid: string,
  input: ActivityTypeInput,
};

export type UpdateActivityTypeMutation = {
  updateActivityType?:  {
    __typename: "ActivityType",
    activitytypeid: string,
    description: string,
    studyid: string,
    study?:  {
      __typename: "Study",
      siteid: string,
      studyid: string,
      status: string,
      name: string,
      identifier?: string | null,
      activitytypes?:  Array< {
        __typename: "ActivityType",
        activitytypeid: string,
        description: string,
        studyid: string,
      } > | null,
      formtypes?:  Array< {
        __typename: "FormType",
        studyid: string,
        formtypeid: string,
        identifier: string,
      } > | null,
      subjects?:  Array< {
        __typename: "Subject",
        siteid: string,
        studyid: string,
        subjectid: string,
        identifier?: string | null,
        status: SubjectStatusType,
        snapshot?: string | null,
      } > | null,
    } | null,
  } | null,
};

export type RemoveActivityTypeMutationVariables = {
  studyid: string,
  activitytypeid: string,
};

export type RemoveActivityTypeMutation = {
  removeActivityType?:  {
    __typename: "ActivityType",
    activitytypeid: string,
    description: string,
    studyid: string,
    study?:  {
      __typename: "Study",
      siteid: string,
      studyid: string,
      status: string,
      name: string,
      identifier?: string | null,
      activitytypes?:  Array< {
        __typename: "ActivityType",
        activitytypeid: string,
        description: string,
        studyid: string,
      } > | null,
      formtypes?:  Array< {
        __typename: "FormType",
        studyid: string,
        formtypeid: string,
        identifier: string,
      } > | null,
      subjects?:  Array< {
        __typename: "Subject",
        siteid: string,
        studyid: string,
        subjectid: string,
        identifier?: string | null,
        status: SubjectStatusType,
        snapshot?: string | null,
      } > | null,
    } | null,
  } | null,
};

export type AddReferenceDataMutationVariables = {
  subjectid: string,
  input: ReferenceDataInput,
};

export type AddReferenceDataMutation = {
  addReferenceData?:  {
    __typename: "ReferenceData",
    referencedataid: string,
    activitytypeid: string,
    siteid: string,
    subjectid: string,
    studyid: string,
    fields: string,
    filename: string,
    persistedObjectName: string,
    objectUrl: string,
    status: ReferenceDataStatusType,
  } | null,
};

export type RemoveReferenceDataMutationVariables = {
  subjectid: string,
  referencedataid: string,
};

export type RemoveReferenceDataMutation = {
  removeReferenceData?:  {
    __typename: "ReferenceData",
    referencedataid: string,
    activitytypeid: string,
    siteid: string,
    subjectid: string,
    studyid: string,
    fields: string,
    filename: string,
    persistedObjectName: string,
    objectUrl: string,
    status: ReferenceDataStatusType,
  } | null,
};

export type GetDeviceQueryVariables = {
  siteid: string,
  deviceid: string,
};

export type GetDeviceQuery = {
  getDevice?:  {
    __typename: "Device",
    siteid: string,
    deviceid: string,
    hw_id?: string | null,
  } | null,
};

export type ListDevicesQueryVariables = {
  siteid?: string | null,
};

export type ListDevicesQuery = {
  listDevices?:  Array< {
    __typename: "Device",
    siteid: string,
    deviceid: string,
    hw_id?: string | null,
  } > | null,
};

export type GetPatientQueryVariables = {
  siteid: string,
  patientid: string,
};

export type GetPatientQuery = {
  getPatient?:  {
    __typename: "Patient",
    siteid: string,
    patientid: string,
    name: string,
  } | null,
};

export type ListPatientsQueryVariables = {
  siteid?: string | null,
};

export type ListPatientsQuery = {
  listPatients?:  Array< {
    __typename: "Patient",
    siteid: string,
    patientid: string,
    name: string,
  } > | null,
};

export type GetSiteQueryVariables = {
  siteid: string,
};

export type GetSiteQuery = {
  getSite?:  {
    __typename: "Site",
    siteid: string,
    name: string,
    devices?:  Array< {
      __typename: "Device",
      siteid: string,
      deviceid: string,
      hw_id?: string | null,
    } > | null,
    patients?:  Array< {
      __typename: "Patient",
      siteid: string,
      patientid: string,
      name: string,
    } > | null,
    studies?:  Array< {
      __typename: "Study",
      siteid: string,
      studyid: string,
      status: string,
      name: string,
      identifier?: string | null,
      activitytypes?:  Array< {
        __typename: "ActivityType",
        activitytypeid: string,
        description: string,
        studyid: string,
      } > | null,
      formtypes?:  Array< {
        __typename: "FormType",
        studyid: string,
        formtypeid: string,
        identifier: string,
      } > | null,
      subjects?:  Array< {
        __typename: "Subject",
        siteid: string,
        studyid: string,
        subjectid: string,
        identifier?: string | null,
        status: SubjectStatusType,
        snapshot?: string | null,
      } > | null,
    } > | null,
  } | null,
};

export type ListSitesQueryVariables = {
};

export type ListSitesQuery = {
  listSites?:  Array< {
    __typename: "Site",
    siteid: string,
    name: string,
    devices?:  Array< {
      __typename: "Device",
      siteid: string,
      deviceid: string,
      hw_id?: string | null,
    } > | null,
    patients?:  Array< {
      __typename: "Patient",
      siteid: string,
      patientid: string,
      name: string,
    } > | null,
    studies?:  Array< {
      __typename: "Study",
      siteid: string,
      studyid: string,
      status: string,
      name: string,
      identifier?: string | null,
      activitytypes?:  Array< {
        __typename: "ActivityType",
        activitytypeid: string,
        description: string,
        studyid: string,
      } > | null,
      formtypes?:  Array< {
        __typename: "FormType",
        studyid: string,
        formtypeid: string,
        identifier: string,
      } > | null,
      subjects?:  Array< {
        __typename: "Subject",
        siteid: string,
        studyid: string,
        subjectid: string,
        identifier?: string | null,
        status: SubjectStatusType,
        snapshot?: string | null,
      } > | null,
    } > | null,
  } > | null,
};

export type GetStudyQueryVariables = {
  siteid: string,
  studyid: string,
};

export type GetStudyQuery = {
  getStudy?:  {
    __typename: "Study",
    siteid: string,
    studyid: string,
    status: string,
    name: string,
    identifier?: string | null,
    activitytypes?:  Array< {
      __typename: "ActivityType",
      activitytypeid: string,
      description: string,
      studyid: string,
      study?:  {
        __typename: "Study",
        siteid: string,
        studyid: string,
        status: string,
        name: string,
        identifier?: string | null,
      } | null,
    } > | null,
    formtypes?:  Array< {
      __typename: "FormType",
      studyid: string,
      formtypeid: string,
      identifier: string,
      fields?:  Array< {
        __typename: "FormTypeField",
        name: string,
        type: string,
        required: boolean,
        length?: number | null,
        pattern?: string | null,
      } > | null,
    } > | null,
    subjects?:  Array< {
      __typename: "Subject",
      siteid: string,
      studyid: string,
      subjectid: string,
      identifier?: string | null,
      status: SubjectStatusType,
      forms?:  Array< {
        __typename: "Form",
        subjectid: string,
        formtypeid: string,
      } > | null,
      activities?:  Array< {
        __typename: "Activity",
        activitytypeid: string,
        subjectid: string,
        comment?: string | null,
        startTime: number,
        stopTime: number,
      } > | null,
      devices?:  Array< {
        __typename: "SubjectDevice",
        deviceid: string,
        position: SubjectDevicePositionType,
      } > | null,
      snapshot?: string | null,
    } > | null,
  } | null,
};

export type ListStudiesQueryVariables = {
  siteid?: string | null,
};

export type ListStudiesQuery = {
  listStudies?:  Array< {
    __typename: "Study",
    siteid: string,
    studyid: string,
    status: string,
    name: string,
    identifier?: string | null,
    activitytypes?:  Array< {
      __typename: "ActivityType",
      activitytypeid: string,
      description: string,
      studyid: string,
      study?:  {
        __typename: "Study",
        siteid: string,
        studyid: string,
        status: string,
        name: string,
        identifier?: string | null,
      } | null,
    } > | null,
    formtypes?:  Array< {
      __typename: "FormType",
      studyid: string,
      formtypeid: string,
      identifier: string,
      fields?:  Array< {
        __typename: "FormTypeField",
        name: string,
        type: string,
        required: boolean,
        length?: number | null,
        pattern?: string | null,
      } > | null,
    } > | null,
    subjects?:  Array< {
      __typename: "Subject",
      siteid: string,
      studyid: string,
      subjectid: string,
      identifier?: string | null,
      status: SubjectStatusType,
      forms?:  Array< {
        __typename: "Form",
        subjectid: string,
        formtypeid: string,
      } > | null,
      activities?:  Array< {
        __typename: "Activity",
        activitytypeid: string,
        subjectid: string,
        comment?: string | null,
        startTime: number,
        stopTime: number,
      } > | null,
      devices?:  Array< {
        __typename: "SubjectDevice",
        deviceid: string,
        position: SubjectDevicePositionType,
      } > | null,
      snapshot?: string | null,
    } > | null,
  } > | null,
};

export type GetSubjectQueryVariables = {
  studyid: string,
  subjectid: string,
};

export type GetSubjectQuery = {
  getSubject?:  {
    __typename: "Subject",
    siteid: string,
    studyid: string,
    subjectid: string,
    identifier?: string | null,
    status: SubjectStatusType,
    forms?:  Array< {
      __typename: "Form",
      subjectid: string,
      formtypeid: string,
      formtype?:  {
        __typename: "FormType",
        studyid: string,
        formtypeid: string,
        identifier: string,
      } | null,
      fields?:  Array< {
        __typename: "KeyValuePair",
        key: string,
        value: string,
      } > | null,
    } > | null,
    activities?:  Array< {
      __typename: "Activity",
      activitytypeid: string,
      subjectid: string,
      comment?: string | null,
      startTime: number,
      stopTime: number,
      activitytype?:  {
        __typename: "ActivityType",
        activitytypeid: string,
        description: string,
        studyid: string,
      } | null,
      referenceData?:  Array< {
        __typename: "ReferenceData",
        referencedataid: string,
        activitytypeid: string,
        siteid: string,
        subjectid: string,
        studyid: string,
        fields: string,
        filename: string,
        persistedObjectName: string,
        objectUrl: string,
        status: ReferenceDataStatusType,
      } > | null,
    } > | null,
    devices?:  Array< {
      __typename: "SubjectDevice",
      deviceid: string,
      position: SubjectDevicePositionType,
    } > | null,
    snapshot?: string | null,
  } | null,
};

export type ListSubjectsQueryVariables = {
  studyid?: string | null,
};

export type ListSubjectsQuery = {
  listSubjects?:  Array< {
    __typename: "Subject",
    siteid: string,
    studyid: string,
    subjectid: string,
    identifier?: string | null,
    status: SubjectStatusType,
    forms?:  Array< {
      __typename: "Form",
      subjectid: string,
      formtypeid: string,
      formtype?:  {
        __typename: "FormType",
        studyid: string,
        formtypeid: string,
        identifier: string,
      } | null,
      fields?:  Array< {
        __typename: "KeyValuePair",
        key: string,
        value: string,
      } > | null,
    } > | null,
    activities?:  Array< {
      __typename: "Activity",
      activitytypeid: string,
      subjectid: string,
      comment?: string | null,
      startTime: number,
      stopTime: number,
      activitytype?:  {
        __typename: "ActivityType",
        activitytypeid: string,
        description: string,
        studyid: string,
      } | null,
      referenceData?:  Array< {
        __typename: "ReferenceData",
        referencedataid: string,
        activitytypeid: string,
        siteid: string,
        subjectid: string,
        studyid: string,
        fields: string,
        filename: string,
        persistedObjectName: string,
        objectUrl: string,
        status: ReferenceDataStatusType,
      } > | null,
    } > | null,
    devices?:  Array< {
      __typename: "SubjectDevice",
      deviceid: string,
      position: SubjectDevicePositionType,
    } > | null,
    snapshot?: string | null,
  } > | null,
};

export type GetFormQueryVariables = {
  subjectid: string,
  formtypeid: string,
};

export type GetFormQuery = {
  getForm?:  {
    __typename: "Form",
    subjectid: string,
    formtypeid: string,
    formtype?:  {
      __typename: "FormType",
      studyid: string,
      formtypeid: string,
      identifier: string,
      fields?:  Array< {
        __typename: "FormTypeField",
        name: string,
        type: string,
        required: boolean,
        length?: number | null,
        pattern?: string | null,
      } > | null,
    } | null,
    fields?:  Array< {
      __typename: "KeyValuePair",
      key: string,
      value: string,
    } > | null,
  } | null,
};

export type ListFormsQueryVariables = {
  subjectid?: string | null,
};

export type ListFormsQuery = {
  listForms?:  Array< {
    __typename: "Form",
    subjectid: string,
    formtypeid: string,
    formtype?:  {
      __typename: "FormType",
      studyid: string,
      formtypeid: string,
      identifier: string,
      fields?:  Array< {
        __typename: "FormTypeField",
        name: string,
        type: string,
        required: boolean,
        length?: number | null,
        pattern?: string | null,
      } > | null,
    } | null,
    fields?:  Array< {
      __typename: "KeyValuePair",
      key: string,
      value: string,
    } > | null,
  } > | null,
};

export type GetFormTypeQueryVariables = {
  studyid: string,
  formtypeid: string,
};

export type GetFormTypeQuery = {
  getFormType?:  {
    __typename: "FormType",
    studyid: string,
    formtypeid: string,
    identifier: string,
    fields?:  Array< {
      __typename: "FormTypeField",
      name: string,
      type: string,
      required: boolean,
      length?: number | null,
      pattern?: string | null,
    } > | null,
  } | null,
};

export type ListFormTypesQueryVariables = {
  studyid?: string | null,
};

export type ListFormTypesQuery = {
  listFormTypes?:  Array< {
    __typename: "FormType",
    studyid: string,
    formtypeid: string,
    identifier: string,
    fields?:  Array< {
      __typename: "FormTypeField",
      name: string,
      type: string,
      required: boolean,
      length?: number | null,
      pattern?: string | null,
    } > | null,
  } > | null,
};

export type GetReferenceDataQueryVariables = {
  subjectid: string,
  referencedataid: string,
};

export type GetReferenceDataQuery = {
  getReferenceData?:  {
    __typename: "ReferenceData",
    referencedataid: string,
    activitytypeid: string,
    siteid: string,
    subjectid: string,
    studyid: string,
    fields: string,
    filename: string,
    persistedObjectName: string,
    objectUrl: string,
    status: ReferenceDataStatusType,
  } | null,
};

export type ListReferenceDataQueryVariables = {
  subjectid?: string | null,
};

export type ListReferenceDataQuery = {
  listReferenceData?:  Array< {
    __typename: "ReferenceData",
    referencedataid: string,
    activitytypeid: string,
    siteid: string,
    subjectid: string,
    studyid: string,
    fields: string,
    filename: string,
    persistedObjectName: string,
    objectUrl: string,
    status: ReferenceDataStatusType,
  } > | null,
};

export type GetActivityQueryVariables = {
  subjectid: string,
  activitytypeid: string,
};

export type GetActivityQuery = {
  getActivity?:  {
    __typename: "Activity",
    activitytypeid: string,
    subjectid: string,
    comment?: string | null,
    startTime: number,
    stopTime: number,
    activitytype?:  {
      __typename: "ActivityType",
      activitytypeid: string,
      description: string,
      studyid: string,
      study?:  {
        __typename: "Study",
        siteid: string,
        studyid: string,
        status: string,
        name: string,
        identifier?: string | null,
      } | null,
    } | null,
    referenceData?:  Array< {
      __typename: "ReferenceData",
      referencedataid: string,
      activitytypeid: string,
      siteid: string,
      subjectid: string,
      studyid: string,
      fields: string,
      filename: string,
      persistedObjectName: string,
      objectUrl: string,
      status: ReferenceDataStatusType,
    } > | null,
  } | null,
};

export type ListActivitiesQueryVariables = {
  subjectid?: string | null,
};

export type ListActivitiesQuery = {
  listActivities?:  Array< {
    __typename: "Activity",
    activitytypeid: string,
    subjectid: string,
    comment?: string | null,
    startTime: number,
    stopTime: number,
    activitytype?:  {
      __typename: "ActivityType",
      activitytypeid: string,
      description: string,
      studyid: string,
      study?:  {
        __typename: "Study",
        siteid: string,
        studyid: string,
        status: string,
        name: string,
        identifier?: string | null,
      } | null,
    } | null,
    referenceData?:  Array< {
      __typename: "ReferenceData",
      referencedataid: string,
      activitytypeid: string,
      siteid: string,
      subjectid: string,
      studyid: string,
      fields: string,
      filename: string,
      persistedObjectName: string,
      objectUrl: string,
      status: ReferenceDataStatusType,
    } > | null,
  } > | null,
};

export type GetActivityTypeQueryVariables = {
  studyid: string,
  activitytypeid: string,
};

export type GetActivityTypeQuery = {
  getActivityType?:  {
    __typename: "ActivityType",
    activitytypeid: string,
    description: string,
    studyid: string,
    study?:  {
      __typename: "Study",
      siteid: string,
      studyid: string,
      status: string,
      name: string,
      identifier?: string | null,
      activitytypes?:  Array< {
        __typename: "ActivityType",
        activitytypeid: string,
        description: string,
        studyid: string,
      } > | null,
      formtypes?:  Array< {
        __typename: "FormType",
        studyid: string,
        formtypeid: string,
        identifier: string,
      } > | null,
      subjects?:  Array< {
        __typename: "Subject",
        siteid: string,
        studyid: string,
        subjectid: string,
        identifier?: string | null,
        status: SubjectStatusType,
        snapshot?: string | null,
      } > | null,
    } | null,
  } | null,
};

export type ListActivityTypesQueryVariables = {
  studyid?: string | null,
};

export type ListActivityTypesQuery = {
  listActivityTypes?:  Array< {
    __typename: "ActivityType",
    activitytypeid: string,
    description: string,
    studyid: string,
    study?:  {
      __typename: "Study",
      siteid: string,
      studyid: string,
      status: string,
      name: string,
      identifier?: string | null,
      activitytypes?:  Array< {
        __typename: "ActivityType",
        activitytypeid: string,
        description: string,
        studyid: string,
      } > | null,
      formtypes?:  Array< {
        __typename: "FormType",
        studyid: string,
        formtypeid: string,
        identifier: string,
      } > | null,
      subjects?:  Array< {
        __typename: "Subject",
        siteid: string,
        studyid: string,
        subjectid: string,
        identifier?: string | null,
        status: SubjectStatusType,
        snapshot?: string | null,
      } > | null,
    } | null,
  } > | null,
};

export type OnActivitiesUpdatesSubscriptionVariables = {
  subjectid: string,
};

export type OnActivitiesUpdatesSubscription = {
  onActivitiesUpdates?:  {
    __typename: "Activity",
    activitytypeid: string,
    subjectid: string,
    comment?: string | null,
    startTime: number,
    stopTime: number,
    activitytype?:  {
      __typename: "ActivityType",
      activitytypeid: string,
      description: string,
      studyid: string,
      study?:  {
        __typename: "Study",
        siteid: string,
        studyid: string,
        status: string,
        name: string,
        identifier?: string | null,
      } | null,
    } | null,
    referenceData?:  Array< {
      __typename: "ReferenceData",
      referencedataid: string,
      activitytypeid: string,
      siteid: string,
      subjectid: string,
      studyid: string,
      fields: string,
      filename: string,
      persistedObjectName: string,
      objectUrl: string,
      status: ReferenceDataStatusType,
    } > | null,
  } | null,
};

export type OnActivityTypesUpdatesSubscriptionVariables = {
  studyid: string,
};

export type OnActivityTypesUpdatesSubscription = {
  onActivityTypesUpdates?:  {
    __typename: "ActivityType",
    activitytypeid: string,
    description: string,
    studyid: string,
    study?:  {
      __typename: "Study",
      siteid: string,
      studyid: string,
      status: string,
      name: string,
      identifier?: string | null,
      activitytypes?:  Array< {
        __typename: "ActivityType",
        activitytypeid: string,
        description: string,
        studyid: string,
      } > | null,
      formtypes?:  Array< {
        __typename: "FormType",
        studyid: string,
        formtypeid: string,
        identifier: string,
      } > | null,
      subjects?:  Array< {
        __typename: "Subject",
        siteid: string,
        studyid: string,
        subjectid: string,
        identifier?: string | null,
        status: SubjectStatusType,
        snapshot?: string | null,
      } > | null,
    } | null,
  } | null,
};

export type OnDevicesUpdatesSubscriptionVariables = {
  siteid: string,
};

export type OnDevicesUpdatesSubscription = {
  onDevicesUpdates?:  {
    __typename: "Device",
    siteid: string,
    deviceid: string,
    hw_id?: string | null,
  } | null,
};

export type OnFormTypesUpdatesSubscriptionVariables = {
  studyid: string,
};

export type OnFormTypesUpdatesSubscription = {
  onFormTypesUpdates?:  {
    __typename: "FormType",
    studyid: string,
    formtypeid: string,
    identifier: string,
    fields?:  Array< {
      __typename: "FormTypeField",
      name: string,
      type: string,
      required: boolean,
      length?: number | null,
      pattern?: string | null,
    } > | null,
  } | null,
};

export type OnFormsUpdatesSubscriptionVariables = {
  subjectid: string,
};

export type OnFormsUpdatesSubscription = {
  onFormsUpdates?:  {
    __typename: "Form",
    subjectid: string,
    formtypeid: string,
    formtype?:  {
      __typename: "FormType",
      studyid: string,
      formtypeid: string,
      identifier: string,
      fields?:  Array< {
        __typename: "FormTypeField",
        name: string,
        type: string,
        required: boolean,
        length?: number | null,
        pattern?: string | null,
      } > | null,
    } | null,
    fields?:  Array< {
      __typename: "KeyValuePair",
      key: string,
      value: string,
    } > | null,
  } | null,
};

export type OnPatientsUpdatesSubscriptionVariables = {
  siteid: string,
};

export type OnPatientsUpdatesSubscription = {
  onPatientsUpdates?:  {
    __typename: "Patient",
    siteid: string,
    patientid: string,
    name: string,
  } | null,
};

export type OnSitesUpdatesSubscriptionVariables = {
};

export type OnSitesUpdatesSubscription = {
  onSitesUpdates?:  {
    __typename: "Site",
    siteid: string,
    name: string,
    devices?:  Array< {
      __typename: "Device",
      siteid: string,
      deviceid: string,
      hw_id?: string | null,
    } > | null,
    patients?:  Array< {
      __typename: "Patient",
      siteid: string,
      patientid: string,
      name: string,
    } > | null,
    studies?:  Array< {
      __typename: "Study",
      siteid: string,
      studyid: string,
      status: string,
      name: string,
      identifier?: string | null,
      activitytypes?:  Array< {
        __typename: "ActivityType",
        activitytypeid: string,
        description: string,
        studyid: string,
      } > | null,
      formtypes?:  Array< {
        __typename: "FormType",
        studyid: string,
        formtypeid: string,
        identifier: string,
      } > | null,
      subjects?:  Array< {
        __typename: "Subject",
        siteid: string,
        studyid: string,
        subjectid: string,
        identifier?: string | null,
        status: SubjectStatusType,
        snapshot?: string | null,
      } > | null,
    } > | null,
  } | null,
};

export type OnStudiesUpdatesSubscriptionVariables = {
  siteid: string,
};

export type OnStudiesUpdatesSubscription = {
  onStudiesUpdates?:  {
    __typename: "Study",
    siteid: string,
    studyid: string,
    status: string,
    name: string,
    identifier?: string | null,
    activitytypes?:  Array< {
      __typename: "ActivityType",
      activitytypeid: string,
      description: string,
      studyid: string,
      study?:  {
        __typename: "Study",
        siteid: string,
        studyid: string,
        status: string,
        name: string,
        identifier?: string | null,
      } | null,
    } > | null,
    formtypes?:  Array< {
      __typename: "FormType",
      studyid: string,
      formtypeid: string,
      identifier: string,
      fields?:  Array< {
        __typename: "FormTypeField",
        name: string,
        type: string,
        required: boolean,
        length?: number | null,
        pattern?: string | null,
      } > | null,
    } > | null,
    subjects?:  Array< {
      __typename: "Subject",
      siteid: string,
      studyid: string,
      subjectid: string,
      identifier?: string | null,
      status: SubjectStatusType,
      forms?:  Array< {
        __typename: "Form",
        subjectid: string,
        formtypeid: string,
      } > | null,
      activities?:  Array< {
        __typename: "Activity",
        activitytypeid: string,
        subjectid: string,
        comment?: string | null,
        startTime: number,
        stopTime: number,
      } > | null,
      devices?:  Array< {
        __typename: "SubjectDevice",
        deviceid: string,
        position: SubjectDevicePositionType,
      } > | null,
      snapshot?: string | null,
    } > | null,
  } | null,
};

export type OnSubjectsUpdatesSubscriptionVariables = {
  studyid: string,
};

export type OnSubjectsUpdatesSubscription = {
  onSubjectsUpdates?:  {
    __typename: "Subject",
    siteid: string,
    studyid: string,
    subjectid: string,
    identifier?: string | null,
    status: SubjectStatusType,
    forms?:  Array< {
      __typename: "Form",
      subjectid: string,
      formtypeid: string,
      formtype?:  {
        __typename: "FormType",
        studyid: string,
        formtypeid: string,
        identifier: string,
      } | null,
      fields?:  Array< {
        __typename: "KeyValuePair",
        key: string,
        value: string,
      } > | null,
    } > | null,
    activities?:  Array< {
      __typename: "Activity",
      activitytypeid: string,
      subjectid: string,
      comment?: string | null,
      startTime: number,
      stopTime: number,
      activitytype?:  {
        __typename: "ActivityType",
        activitytypeid: string,
        description: string,
        studyid: string,
      } | null,
      referenceData?:  Array< {
        __typename: "ReferenceData",
        referencedataid: string,
        activitytypeid: string,
        siteid: string,
        subjectid: string,
        studyid: string,
        fields: string,
        filename: string,
        persistedObjectName: string,
        objectUrl: string,
        status: ReferenceDataStatusType,
      } > | null,
    } > | null,
    devices?:  Array< {
      __typename: "SubjectDevice",
      deviceid: string,
      position: SubjectDevicePositionType,
    } > | null,
    snapshot?: string | null,
  } | null,
};

export type OnSubjectUpdatesSubscriptionVariables = {
  studyid: string,
  subjectid: string,
};

export type OnSubjectUpdatesSubscription = {
  onSubjectUpdates?:  {
    __typename: "Subject",
    siteid: string,
    studyid: string,
    subjectid: string,
    identifier?: string | null,
    status: SubjectStatusType,
    forms?:  Array< {
      __typename: "Form",
      subjectid: string,
      formtypeid: string,
      formtype?:  {
        __typename: "FormType",
        studyid: string,
        formtypeid: string,
        identifier: string,
      } | null,
      fields?:  Array< {
        __typename: "KeyValuePair",
        key: string,
        value: string,
      } > | null,
    } > | null,
    activities?:  Array< {
      __typename: "Activity",
      activitytypeid: string,
      subjectid: string,
      comment?: string | null,
      startTime: number,
      stopTime: number,
      activitytype?:  {
        __typename: "ActivityType",
        activitytypeid: string,
        description: string,
        studyid: string,
      } | null,
      referenceData?:  Array< {
        __typename: "ReferenceData",
        referencedataid: string,
        activitytypeid: string,
        siteid: string,
        subjectid: string,
        studyid: string,
        fields: string,
        filename: string,
        persistedObjectName: string,
        objectUrl: string,
        status: ReferenceDataStatusType,
      } > | null,
    } > | null,
    devices?:  Array< {
      __typename: "SubjectDevice",
      deviceid: string,
      position: SubjectDevicePositionType,
    } > | null,
    snapshot?: string | null,
  } | null,
};

export type OnReferenceDataUpdatesSubscriptionVariables = {
  subjectid: string,
  activitytypeid: string,
};

export type OnReferenceDataUpdatesSubscription = {
  onReferenceDataUpdates?:  {
    __typename: "ReferenceData",
    referencedataid: string,
    activitytypeid: string,
    siteid: string,
    subjectid: string,
    studyid: string,
    fields: string,
    filename: string,
    persistedObjectName: string,
    objectUrl: string,
    status: ReferenceDataStatusType,
  } | null,
};
