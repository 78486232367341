/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "./API";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const addDevice = /* GraphQL */ `mutation AddDevice($siteid: ID!, $deviceid: String!) {
  addDevice(siteid: $siteid, deviceid: $deviceid) {
    siteid
    deviceid
    hw_id
    __typename
  }
}
` as GeneratedMutation<
  APITypes.AddDeviceMutationVariables,
  APITypes.AddDeviceMutation
>;
export const updateDevice = /* GraphQL */ `mutation UpdateDevice($siteid: ID!, $deviceid: String!) {
  updateDevice(siteid: $siteid, deviceid: $deviceid) {
    siteid
    deviceid
    hw_id
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateDeviceMutationVariables,
  APITypes.UpdateDeviceMutation
>;
export const removeDevice = /* GraphQL */ `mutation RemoveDevice($siteid: ID!, $deviceid: String!) {
  removeDevice(siteid: $siteid, deviceid: $deviceid) {
    siteid
    deviceid
    hw_id
    __typename
  }
}
` as GeneratedMutation<
  APITypes.RemoveDeviceMutationVariables,
  APITypes.RemoveDeviceMutation
>;
export const addPatient = /* GraphQL */ `mutation AddPatient($siteid: ID!, $name: String!) {
  addPatient(siteid: $siteid, name: $name) {
    siteid
    patientid
    name
    __typename
  }
}
` as GeneratedMutation<
  APITypes.AddPatientMutationVariables,
  APITypes.AddPatientMutation
>;
export const updatePatient = /* GraphQL */ `mutation UpdatePatient($siteid: ID!, $patientid: String!, $name: String!) {
  updatePatient(siteid: $siteid, patientid: $patientid, name: $name) {
    siteid
    patientid
    name
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdatePatientMutationVariables,
  APITypes.UpdatePatientMutation
>;
export const removePatient = /* GraphQL */ `mutation RemovePatient($siteid: ID!, $patientid: String!) {
  removePatient(siteid: $siteid, patientid: $patientid) {
    siteid
    patientid
    name
    __typename
  }
}
` as GeneratedMutation<
  APITypes.RemovePatientMutationVariables,
  APITypes.RemovePatientMutation
>;
export const addSite = /* GraphQL */ `mutation AddSite($siteid: ID!, $name: String!) {
  addSite(siteid: $siteid, name: $name) {
    siteid
    name
    devices {
      siteid
      deviceid
      hw_id
      __typename
    }
    patients {
      siteid
      patientid
      name
      __typename
    }
    studies {
      siteid
      studyid
      status
      name
      identifier
      activitytypes {
        activitytypeid
        description
        studyid
        __typename
      }
      formtypes {
        studyid
        formtypeid
        identifier
        __typename
      }
      subjects {
        siteid
        studyid
        subjectid
        identifier
        status
        snapshot
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.AddSiteMutationVariables,
  APITypes.AddSiteMutation
>;
export const updateSite = /* GraphQL */ `mutation UpdateSite($siteid: ID!, $name: String!) {
  updateSite(siteid: $siteid, name: $name) {
    siteid
    name
    devices {
      siteid
      deviceid
      hw_id
      __typename
    }
    patients {
      siteid
      patientid
      name
      __typename
    }
    studies {
      siteid
      studyid
      status
      name
      identifier
      activitytypes {
        activitytypeid
        description
        studyid
        __typename
      }
      formtypes {
        studyid
        formtypeid
        identifier
        __typename
      }
      subjects {
        siteid
        studyid
        subjectid
        identifier
        status
        snapshot
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateSiteMutationVariables,
  APITypes.UpdateSiteMutation
>;
export const removeSite = /* GraphQL */ `mutation RemoveSite($siteid: ID!) {
  removeSite(siteid: $siteid) {
    siteid
    name
    devices {
      siteid
      deviceid
      hw_id
      __typename
    }
    patients {
      siteid
      patientid
      name
      __typename
    }
    studies {
      siteid
      studyid
      status
      name
      identifier
      activitytypes {
        activitytypeid
        description
        studyid
        __typename
      }
      formtypes {
        studyid
        formtypeid
        identifier
        __typename
      }
      subjects {
        siteid
        studyid
        subjectid
        identifier
        status
        snapshot
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.RemoveSiteMutationVariables,
  APITypes.RemoveSiteMutation
>;
export const addStudy = /* GraphQL */ `mutation AddStudy($siteid: ID!, $name: String!) {
  addStudy(siteid: $siteid, name: $name) {
    siteid
    studyid
    status
    name
    identifier
    activitytypes {
      activitytypeid
      description
      studyid
      study {
        siteid
        studyid
        status
        name
        identifier
        __typename
      }
      __typename
    }
    formtypes {
      studyid
      formtypeid
      identifier
      fields {
        name
        type
        required
        length
        pattern
        __typename
      }
      __typename
    }
    subjects {
      siteid
      studyid
      subjectid
      identifier
      status
      forms {
        subjectid
        formtypeid
        __typename
      }
      activities {
        activitytypeid
        subjectid
        comment
        startTime
        stopTime
        __typename
      }
      devices {
        deviceid
        position
        __typename
      }
      snapshot
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.AddStudyMutationVariables,
  APITypes.AddStudyMutation
>;
export const updateStudy = /* GraphQL */ `mutation UpdateStudy(
  $siteid: ID!
  $studyid: ID!
  $name: String
  $status: String
) {
  updateStudy(
    siteid: $siteid
    studyid: $studyid
    name: $name
    status: $status
  ) {
    siteid
    studyid
    status
    name
    identifier
    activitytypes {
      activitytypeid
      description
      studyid
      study {
        siteid
        studyid
        status
        name
        identifier
        __typename
      }
      __typename
    }
    formtypes {
      studyid
      formtypeid
      identifier
      fields {
        name
        type
        required
        length
        pattern
        __typename
      }
      __typename
    }
    subjects {
      siteid
      studyid
      subjectid
      identifier
      status
      forms {
        subjectid
        formtypeid
        __typename
      }
      activities {
        activitytypeid
        subjectid
        comment
        startTime
        stopTime
        __typename
      }
      devices {
        deviceid
        position
        __typename
      }
      snapshot
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateStudyMutationVariables,
  APITypes.UpdateStudyMutation
>;
export const removeStudy = /* GraphQL */ `mutation RemoveStudy($siteid: ID!, $studyid: ID!) {
  removeStudy(siteid: $siteid, studyid: $studyid) {
    siteid
    studyid
    status
    name
    identifier
    activitytypes {
      activitytypeid
      description
      studyid
      study {
        siteid
        studyid
        status
        name
        identifier
        __typename
      }
      __typename
    }
    formtypes {
      studyid
      formtypeid
      identifier
      fields {
        name
        type
        required
        length
        pattern
        __typename
      }
      __typename
    }
    subjects {
      siteid
      studyid
      subjectid
      identifier
      status
      forms {
        subjectid
        formtypeid
        __typename
      }
      activities {
        activitytypeid
        subjectid
        comment
        startTime
        stopTime
        __typename
      }
      devices {
        deviceid
        position
        __typename
      }
      snapshot
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.RemoveStudyMutationVariables,
  APITypes.RemoveStudyMutation
>;
export const addSubject = /* GraphQL */ `mutation AddSubject($input: SubjectInput!) {
  addSubject(input: $input) {
    siteid
    studyid
    subjectid
    identifier
    status
    forms {
      subjectid
      formtypeid
      formtype {
        studyid
        formtypeid
        identifier
        __typename
      }
      fields {
        key
        value
        __typename
      }
      __typename
    }
    activities {
      activitytypeid
      subjectid
      comment
      startTime
      stopTime
      activitytype {
        activitytypeid
        description
        studyid
        __typename
      }
      referenceData {
        referencedataid
        activitytypeid
        siteid
        subjectid
        studyid
        fields
        filename
        persistedObjectName
        objectUrl
        status
        __typename
      }
      __typename
    }
    devices {
      deviceid
      position
      __typename
    }
    snapshot
    __typename
  }
}
` as GeneratedMutation<
  APITypes.AddSubjectMutationVariables,
  APITypes.AddSubjectMutation
>;
export const addSubjectV2 = /* GraphQL */ `mutation AddSubjectV2($input: SubjectInputV2!) {
  addSubjectV2(input: $input) {
    siteid
    studyid
    subjectid
    identifier
    status
    forms {
      subjectid
      formtypeid
      formtype {
        studyid
        formtypeid
        identifier
        __typename
      }
      fields {
        key
        value
        __typename
      }
      __typename
    }
    activities {
      activitytypeid
      subjectid
      comment
      startTime
      stopTime
      activitytype {
        activitytypeid
        description
        studyid
        __typename
      }
      referenceData {
        referencedataid
        activitytypeid
        siteid
        subjectid
        studyid
        fields
        filename
        persistedObjectName
        objectUrl
        status
        __typename
      }
      __typename
    }
    devices {
      deviceid
      position
      __typename
    }
    snapshot
    __typename
  }
}
` as GeneratedMutation<
  APITypes.AddSubjectV2MutationVariables,
  APITypes.AddSubjectV2Mutation
>;
export const updateSubject = /* GraphQL */ `mutation UpdateSubject($input: SubjectUpdateInput!) {
  updateSubject(input: $input) {
    siteid
    studyid
    subjectid
    identifier
    status
    forms {
      subjectid
      formtypeid
      formtype {
        studyid
        formtypeid
        identifier
        __typename
      }
      fields {
        key
        value
        __typename
      }
      __typename
    }
    activities {
      activitytypeid
      subjectid
      comment
      startTime
      stopTime
      activitytype {
        activitytypeid
        description
        studyid
        __typename
      }
      referenceData {
        referencedataid
        activitytypeid
        siteid
        subjectid
        studyid
        fields
        filename
        persistedObjectName
        objectUrl
        status
        __typename
      }
      __typename
    }
    devices {
      deviceid
      position
      __typename
    }
    snapshot
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateSubjectMutationVariables,
  APITypes.UpdateSubjectMutation
>;
export const removeSubject = /* GraphQL */ `mutation RemoveSubject($studyid: ID!, $subjectid: ID!) {
  removeSubject(studyid: $studyid, subjectid: $subjectid) {
    siteid
    studyid
    subjectid
    identifier
    status
    forms {
      subjectid
      formtypeid
      formtype {
        studyid
        formtypeid
        identifier
        __typename
      }
      fields {
        key
        value
        __typename
      }
      __typename
    }
    activities {
      activitytypeid
      subjectid
      comment
      startTime
      stopTime
      activitytype {
        activitytypeid
        description
        studyid
        __typename
      }
      referenceData {
        referencedataid
        activitytypeid
        siteid
        subjectid
        studyid
        fields
        filename
        persistedObjectName
        objectUrl
        status
        __typename
      }
      __typename
    }
    devices {
      deviceid
      position
      __typename
    }
    snapshot
    __typename
  }
}
` as GeneratedMutation<
  APITypes.RemoveSubjectMutationVariables,
  APITypes.RemoveSubjectMutation
>;
export const addFormType = /* GraphQL */ `mutation AddFormType($input: FormTypeInput!) {
  addFormType(input: $input) {
    studyid
    formtypeid
    identifier
    fields {
      name
      type
      required
      length
      pattern
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.AddFormTypeMutationVariables,
  APITypes.AddFormTypeMutation
>;
export const updateFormType = /* GraphQL */ `mutation UpdateFormType($formtypeid: ID!, $input: FormTypeInput) {
  updateFormType(formtypeid: $formtypeid, input: $input) {
    studyid
    formtypeid
    identifier
    fields {
      name
      type
      required
      length
      pattern
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateFormTypeMutationVariables,
  APITypes.UpdateFormTypeMutation
>;
export const removeFormType = /* GraphQL */ `mutation RemoveFormType($studyid: ID!, $formtypeid: ID!) {
  removeFormType(studyid: $studyid, formtypeid: $formtypeid) {
    studyid
    formtypeid
    identifier
    fields {
      name
      type
      required
      length
      pattern
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.RemoveFormTypeMutationVariables,
  APITypes.RemoveFormTypeMutation
>;
export const addForm = /* GraphQL */ `mutation AddForm($input: FormInput!) {
  addForm(input: $input) {
    subjectid
    formtypeid
    formtype {
      studyid
      formtypeid
      identifier
      fields {
        name
        type
        required
        length
        pattern
        __typename
      }
      __typename
    }
    fields {
      key
      value
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.AddFormMutationVariables,
  APITypes.AddFormMutation
>;
export const updateForm = /* GraphQL */ `mutation UpdateForm($input: FormInput!) {
  updateForm(input: $input) {
    subjectid
    formtypeid
    formtype {
      studyid
      formtypeid
      identifier
      fields {
        name
        type
        required
        length
        pattern
        __typename
      }
      __typename
    }
    fields {
      key
      value
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateFormMutationVariables,
  APITypes.UpdateFormMutation
>;
export const removeForm = /* GraphQL */ `mutation RemoveForm($subjectid: ID!, $formtypeid: ID!) {
  removeForm(subjectid: $subjectid, formtypeid: $formtypeid) {
    subjectid
    formtypeid
    formtype {
      studyid
      formtypeid
      identifier
      fields {
        name
        type
        required
        length
        pattern
        __typename
      }
      __typename
    }
    fields {
      key
      value
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.RemoveFormMutationVariables,
  APITypes.RemoveFormMutation
>;
export const addActivity = /* GraphQL */ `mutation AddActivity($input: ActivityInput!) {
  addActivity(input: $input) {
    activitytypeid
    subjectid
    comment
    startTime
    stopTime
    activitytype {
      activitytypeid
      description
      studyid
      study {
        siteid
        studyid
        status
        name
        identifier
        __typename
      }
      __typename
    }
    referenceData {
      referencedataid
      activitytypeid
      siteid
      subjectid
      studyid
      fields
      filename
      persistedObjectName
      objectUrl
      status
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.AddActivityMutationVariables,
  APITypes.AddActivityMutation
>;
export const updateActivity = /* GraphQL */ `mutation UpdateActivity($input: ActivityInput!) {
  updateActivity(input: $input) {
    activitytypeid
    subjectid
    comment
    startTime
    stopTime
    activitytype {
      activitytypeid
      description
      studyid
      study {
        siteid
        studyid
        status
        name
        identifier
        __typename
      }
      __typename
    }
    referenceData {
      referencedataid
      activitytypeid
      siteid
      subjectid
      studyid
      fields
      filename
      persistedObjectName
      objectUrl
      status
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateActivityMutationVariables,
  APITypes.UpdateActivityMutation
>;
export const removeActivity = /* GraphQL */ `mutation RemoveActivity($subjectid: ID!, $activitytypeid: ID!) {
  removeActivity(subjectid: $subjectid, activitytypeid: $activitytypeid) {
    activitytypeid
    subjectid
    comment
    startTime
    stopTime
    activitytype {
      activitytypeid
      description
      studyid
      study {
        siteid
        studyid
        status
        name
        identifier
        __typename
      }
      __typename
    }
    referenceData {
      referencedataid
      activitytypeid
      siteid
      subjectid
      studyid
      fields
      filename
      persistedObjectName
      objectUrl
      status
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.RemoveActivityMutationVariables,
  APITypes.RemoveActivityMutation
>;
export const addActivityType = /* GraphQL */ `mutation AddActivityType($input: ActivityTypeInput!) {
  addActivityType(input: $input) {
    activitytypeid
    description
    studyid
    study {
      siteid
      studyid
      status
      name
      identifier
      activitytypes {
        activitytypeid
        description
        studyid
        __typename
      }
      formtypes {
        studyid
        formtypeid
        identifier
        __typename
      }
      subjects {
        siteid
        studyid
        subjectid
        identifier
        status
        snapshot
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.AddActivityTypeMutationVariables,
  APITypes.AddActivityTypeMutation
>;
export const updateActivityType = /* GraphQL */ `mutation UpdateActivityType($activitytypeid: ID!, $input: ActivityTypeInput!) {
  updateActivityType(activitytypeid: $activitytypeid, input: $input) {
    activitytypeid
    description
    studyid
    study {
      siteid
      studyid
      status
      name
      identifier
      activitytypes {
        activitytypeid
        description
        studyid
        __typename
      }
      formtypes {
        studyid
        formtypeid
        identifier
        __typename
      }
      subjects {
        siteid
        studyid
        subjectid
        identifier
        status
        snapshot
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateActivityTypeMutationVariables,
  APITypes.UpdateActivityTypeMutation
>;
export const removeActivityType = /* GraphQL */ `mutation RemoveActivityType($studyid: ID!, $activitytypeid: ID!) {
  removeActivityType(studyid: $studyid, activitytypeid: $activitytypeid) {
    activitytypeid
    description
    studyid
    study {
      siteid
      studyid
      status
      name
      identifier
      activitytypes {
        activitytypeid
        description
        studyid
        __typename
      }
      formtypes {
        studyid
        formtypeid
        identifier
        __typename
      }
      subjects {
        siteid
        studyid
        subjectid
        identifier
        status
        snapshot
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.RemoveActivityTypeMutationVariables,
  APITypes.RemoveActivityTypeMutation
>;
export const addReferenceData = /* GraphQL */ `mutation AddReferenceData($subjectid: ID!, $input: ReferenceDataInput!) {
  addReferenceData(subjectid: $subjectid, input: $input) {
    referencedataid
    activitytypeid
    siteid
    subjectid
    studyid
    fields
    filename
    persistedObjectName
    objectUrl
    status
    __typename
  }
}
` as GeneratedMutation<
  APITypes.AddReferenceDataMutationVariables,
  APITypes.AddReferenceDataMutation
>;
export const removeReferenceData = /* GraphQL */ `mutation RemoveReferenceData($subjectid: ID!, $referencedataid: ID!) {
  removeReferenceData(
    subjectid: $subjectid
    referencedataid: $referencedataid
  ) {
    referencedataid
    activitytypeid
    siteid
    subjectid
    studyid
    fields
    filename
    persistedObjectName
    objectUrl
    status
    __typename
  }
}
` as GeneratedMutation<
  APITypes.RemoveReferenceDataMutationVariables,
  APITypes.RemoveReferenceDataMutation
>;
