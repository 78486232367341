/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "./API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const getDevice = /* GraphQL */ `query GetDevice($siteid: ID!, $deviceid: String!) {
  getDevice(siteid: $siteid, deviceid: $deviceid) {
    siteid
    deviceid
    hw_id
    __typename
  }
}
` as GeneratedQuery<APITypes.GetDeviceQueryVariables, APITypes.GetDeviceQuery>;
export const listDevices = /* GraphQL */ `query ListDevices($siteid: ID) {
  listDevices(siteid: $siteid) {
    siteid
    deviceid
    hw_id
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListDevicesQueryVariables,
  APITypes.ListDevicesQuery
>;
export const getPatient = /* GraphQL */ `query GetPatient($siteid: ID!, $patientid: String!) {
  getPatient(siteid: $siteid, patientid: $patientid) {
    siteid
    patientid
    name
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetPatientQueryVariables,
  APITypes.GetPatientQuery
>;
export const listPatients = /* GraphQL */ `query ListPatients($siteid: ID) {
  listPatients(siteid: $siteid) {
    siteid
    patientid
    name
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListPatientsQueryVariables,
  APITypes.ListPatientsQuery
>;
export const getSite = /* GraphQL */ `query GetSite($siteid: ID!) {
  getSite(siteid: $siteid) {
    siteid
    name
    devices {
      siteid
      deviceid
      hw_id
      __typename
    }
    patients {
      siteid
      patientid
      name
      __typename
    }
    studies {
      siteid
      studyid
      status
      name
      identifier
      activitytypes {
        activitytypeid
        description
        studyid
        __typename
      }
      formtypes {
        studyid
        formtypeid
        identifier
        __typename
      }
      subjects {
        siteid
        studyid
        subjectid
        identifier
        status
        snapshot
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<APITypes.GetSiteQueryVariables, APITypes.GetSiteQuery>;
export const listSites = /* GraphQL */ `query ListSites {
  listSites {
    siteid
    name
    devices {
      siteid
      deviceid
      hw_id
      __typename
    }
    patients {
      siteid
      patientid
      name
      __typename
    }
    studies {
      siteid
      studyid
      status
      name
      identifier
      activitytypes {
        activitytypeid
        description
        studyid
        __typename
      }
      formtypes {
        studyid
        formtypeid
        identifier
        __typename
      }
      subjects {
        siteid
        studyid
        subjectid
        identifier
        status
        snapshot
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<APITypes.ListSitesQueryVariables, APITypes.ListSitesQuery>;
export const getStudy = /* GraphQL */ `query GetStudy($siteid: ID!, $studyid: ID!) {
  getStudy(siteid: $siteid, studyid: $studyid) {
    siteid
    studyid
    status
    name
    identifier
    activitytypes {
      activitytypeid
      description
      studyid
      study {
        siteid
        studyid
        status
        name
        identifier
        __typename
      }
      __typename
    }
    formtypes {
      studyid
      formtypeid
      identifier
      fields {
        name
        type
        required
        length
        pattern
        __typename
      }
      __typename
    }
    subjects {
      siteid
      studyid
      subjectid
      identifier
      status
      forms {
        subjectid
        formtypeid
        __typename
      }
      activities {
        activitytypeid
        subjectid
        comment
        startTime
        stopTime
        __typename
      }
      devices {
        deviceid
        position
        __typename
      }
      snapshot
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<APITypes.GetStudyQueryVariables, APITypes.GetStudyQuery>;
export const listStudies = /* GraphQL */ `query ListStudies($siteid: ID) {
  listStudies(siteid: $siteid) {
    siteid
    studyid
    status
    name
    identifier
    activitytypes {
      activitytypeid
      description
      studyid
      study {
        siteid
        studyid
        status
        name
        identifier
        __typename
      }
      __typename
    }
    formtypes {
      studyid
      formtypeid
      identifier
      fields {
        name
        type
        required
        length
        pattern
        __typename
      }
      __typename
    }
    subjects {
      siteid
      studyid
      subjectid
      identifier
      status
      forms {
        subjectid
        formtypeid
        __typename
      }
      activities {
        activitytypeid
        subjectid
        comment
        startTime
        stopTime
        __typename
      }
      devices {
        deviceid
        position
        __typename
      }
      snapshot
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListStudiesQueryVariables,
  APITypes.ListStudiesQuery
>;
export const getSubject = /* GraphQL */ `query GetSubject($studyid: ID!, $subjectid: ID!) {
  getSubject(studyid: $studyid, subjectid: $subjectid) {
    siteid
    studyid
    subjectid
    identifier
    status
    forms {
      subjectid
      formtypeid
      formtype {
        studyid
        formtypeid
        identifier
        __typename
      }
      fields {
        key
        value
        __typename
      }
      __typename
    }
    activities {
      activitytypeid
      subjectid
      comment
      startTime
      stopTime
      activitytype {
        activitytypeid
        description
        studyid
        __typename
      }
      referenceData {
        referencedataid
        activitytypeid
        siteid
        subjectid
        studyid
        fields
        filename
        persistedObjectName
        objectUrl
        status
        __typename
      }
      __typename
    }
    devices {
      deviceid
      position
      __typename
    }
    snapshot
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetSubjectQueryVariables,
  APITypes.GetSubjectQuery
>;
export const listSubjects = /* GraphQL */ `query ListSubjects($studyid: ID) {
  listSubjects(studyid: $studyid) {
    siteid
    studyid
    subjectid
    identifier
    status
    forms {
      subjectid
      formtypeid
      formtype {
        studyid
        formtypeid
        identifier
        __typename
      }
      fields {
        key
        value
        __typename
      }
      __typename
    }
    activities {
      activitytypeid
      subjectid
      comment
      startTime
      stopTime
      activitytype {
        activitytypeid
        description
        studyid
        __typename
      }
      referenceData {
        referencedataid
        activitytypeid
        siteid
        subjectid
        studyid
        fields
        filename
        persistedObjectName
        objectUrl
        status
        __typename
      }
      __typename
    }
    devices {
      deviceid
      position
      __typename
    }
    snapshot
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListSubjectsQueryVariables,
  APITypes.ListSubjectsQuery
>;
export const getForm = /* GraphQL */ `query GetForm($subjectid: ID!, $formtypeid: ID!) {
  getForm(subjectid: $subjectid, formtypeid: $formtypeid) {
    subjectid
    formtypeid
    formtype {
      studyid
      formtypeid
      identifier
      fields {
        name
        type
        required
        length
        pattern
        __typename
      }
      __typename
    }
    fields {
      key
      value
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<APITypes.GetFormQueryVariables, APITypes.GetFormQuery>;
export const listForms = /* GraphQL */ `query ListForms($subjectid: ID) {
  listForms(subjectid: $subjectid) {
    subjectid
    formtypeid
    formtype {
      studyid
      formtypeid
      identifier
      fields {
        name
        type
        required
        length
        pattern
        __typename
      }
      __typename
    }
    fields {
      key
      value
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<APITypes.ListFormsQueryVariables, APITypes.ListFormsQuery>;
export const getFormType = /* GraphQL */ `query GetFormType($studyid: ID!, $formtypeid: ID!) {
  getFormType(studyid: $studyid, formtypeid: $formtypeid) {
    studyid
    formtypeid
    identifier
    fields {
      name
      type
      required
      length
      pattern
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetFormTypeQueryVariables,
  APITypes.GetFormTypeQuery
>;
export const listFormTypes = /* GraphQL */ `query ListFormTypes($studyid: ID) {
  listFormTypes(studyid: $studyid) {
    studyid
    formtypeid
    identifier
    fields {
      name
      type
      required
      length
      pattern
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListFormTypesQueryVariables,
  APITypes.ListFormTypesQuery
>;
export const getReferenceData = /* GraphQL */ `query GetReferenceData($subjectid: ID!, $referencedataid: ID!) {
  getReferenceData(subjectid: $subjectid, referencedataid: $referencedataid) {
    referencedataid
    activitytypeid
    siteid
    subjectid
    studyid
    fields
    filename
    persistedObjectName
    objectUrl
    status
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetReferenceDataQueryVariables,
  APITypes.GetReferenceDataQuery
>;
export const listReferenceData = /* GraphQL */ `query ListReferenceData($subjectid: ID) {
  listReferenceData(subjectid: $subjectid) {
    referencedataid
    activitytypeid
    siteid
    subjectid
    studyid
    fields
    filename
    persistedObjectName
    objectUrl
    status
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListReferenceDataQueryVariables,
  APITypes.ListReferenceDataQuery
>;
export const getActivity = /* GraphQL */ `query GetActivity($subjectid: ID!, $activitytypeid: ID!) {
  getActivity(subjectid: $subjectid, activitytypeid: $activitytypeid) {
    activitytypeid
    subjectid
    comment
    startTime
    stopTime
    activitytype {
      activitytypeid
      description
      studyid
      study {
        siteid
        studyid
        status
        name
        identifier
        __typename
      }
      __typename
    }
    referenceData {
      referencedataid
      activitytypeid
      siteid
      subjectid
      studyid
      fields
      filename
      persistedObjectName
      objectUrl
      status
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetActivityQueryVariables,
  APITypes.GetActivityQuery
>;
export const listActivities = /* GraphQL */ `query ListActivities($subjectid: ID) {
  listActivities(subjectid: $subjectid) {
    activitytypeid
    subjectid
    comment
    startTime
    stopTime
    activitytype {
      activitytypeid
      description
      studyid
      study {
        siteid
        studyid
        status
        name
        identifier
        __typename
      }
      __typename
    }
    referenceData {
      referencedataid
      activitytypeid
      siteid
      subjectid
      studyid
      fields
      filename
      persistedObjectName
      objectUrl
      status
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListActivitiesQueryVariables,
  APITypes.ListActivitiesQuery
>;
export const getActivityType = /* GraphQL */ `query GetActivityType($studyid: ID!, $activitytypeid: ID!) {
  getActivityType(studyid: $studyid, activitytypeid: $activitytypeid) {
    activitytypeid
    description
    studyid
    study {
      siteid
      studyid
      status
      name
      identifier
      activitytypes {
        activitytypeid
        description
        studyid
        __typename
      }
      formtypes {
        studyid
        formtypeid
        identifier
        __typename
      }
      subjects {
        siteid
        studyid
        subjectid
        identifier
        status
        snapshot
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetActivityTypeQueryVariables,
  APITypes.GetActivityTypeQuery
>;
export const listActivityTypes = /* GraphQL */ `query ListActivityTypes($studyid: ID) {
  listActivityTypes(studyid: $studyid) {
    activitytypeid
    description
    studyid
    study {
      siteid
      studyid
      status
      name
      identifier
      activitytypes {
        activitytypeid
        description
        studyid
        __typename
      }
      formtypes {
        studyid
        formtypeid
        identifier
        __typename
      }
      subjects {
        siteid
        studyid
        subjectid
        identifier
        status
        snapshot
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListActivityTypesQueryVariables,
  APITypes.ListActivityTypesQuery
>;
