/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "./API";
type GeneratedSubscription<InputType, OutputType> = string & {
  __generatedSubscriptionInput: InputType;
  __generatedSubscriptionOutput: OutputType;
};

export const onActivitiesUpdates = /* GraphQL */ `subscription OnActivitiesUpdates($subjectid: ID!) {
  onActivitiesUpdates(subjectid: $subjectid) {
    activitytypeid
    subjectid
    comment
    startTime
    stopTime
    activitytype {
      activitytypeid
      description
      studyid
      study {
        siteid
        studyid
        status
        name
        identifier
        __typename
      }
      __typename
    }
    referenceData {
      referencedataid
      activitytypeid
      siteid
      subjectid
      studyid
      fields
      filename
      persistedObjectName
      objectUrl
      status
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnActivitiesUpdatesSubscriptionVariables,
  APITypes.OnActivitiesUpdatesSubscription
>;
export const onActivityTypesUpdates = /* GraphQL */ `subscription OnActivityTypesUpdates($studyid: ID!) {
  onActivityTypesUpdates(studyid: $studyid) {
    activitytypeid
    description
    studyid
    study {
      siteid
      studyid
      status
      name
      identifier
      activitytypes {
        activitytypeid
        description
        studyid
        __typename
      }
      formtypes {
        studyid
        formtypeid
        identifier
        __typename
      }
      subjects {
        siteid
        studyid
        subjectid
        identifier
        status
        snapshot
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnActivityTypesUpdatesSubscriptionVariables,
  APITypes.OnActivityTypesUpdatesSubscription
>;
export const onDevicesUpdates = /* GraphQL */ `subscription OnDevicesUpdates($siteid: ID!) {
  onDevicesUpdates(siteid: $siteid) {
    siteid
    deviceid
    hw_id
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDevicesUpdatesSubscriptionVariables,
  APITypes.OnDevicesUpdatesSubscription
>;
export const onFormTypesUpdates = /* GraphQL */ `subscription OnFormTypesUpdates($studyid: ID!) {
  onFormTypesUpdates(studyid: $studyid) {
    studyid
    formtypeid
    identifier
    fields {
      name
      type
      required
      length
      pattern
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnFormTypesUpdatesSubscriptionVariables,
  APITypes.OnFormTypesUpdatesSubscription
>;
export const onFormsUpdates = /* GraphQL */ `subscription OnFormsUpdates($subjectid: ID!) {
  onFormsUpdates(subjectid: $subjectid) {
    subjectid
    formtypeid
    formtype {
      studyid
      formtypeid
      identifier
      fields {
        name
        type
        required
        length
        pattern
        __typename
      }
      __typename
    }
    fields {
      key
      value
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnFormsUpdatesSubscriptionVariables,
  APITypes.OnFormsUpdatesSubscription
>;
export const onPatientsUpdates = /* GraphQL */ `subscription OnPatientsUpdates($siteid: ID!) {
  onPatientsUpdates(siteid: $siteid) {
    siteid
    patientid
    name
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnPatientsUpdatesSubscriptionVariables,
  APITypes.OnPatientsUpdatesSubscription
>;
export const onSitesUpdates = /* GraphQL */ `subscription OnSitesUpdates {
  onSitesUpdates {
    siteid
    name
    devices {
      siteid
      deviceid
      hw_id
      __typename
    }
    patients {
      siteid
      patientid
      name
      __typename
    }
    studies {
      siteid
      studyid
      status
      name
      identifier
      activitytypes {
        activitytypeid
        description
        studyid
        __typename
      }
      formtypes {
        studyid
        formtypeid
        identifier
        __typename
      }
      subjects {
        siteid
        studyid
        subjectid
        identifier
        status
        snapshot
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnSitesUpdatesSubscriptionVariables,
  APITypes.OnSitesUpdatesSubscription
>;
export const onStudiesUpdates = /* GraphQL */ `subscription OnStudiesUpdates($siteid: ID!) {
  onStudiesUpdates(siteid: $siteid) {
    siteid
    studyid
    status
    name
    identifier
    activitytypes {
      activitytypeid
      description
      studyid
      study {
        siteid
        studyid
        status
        name
        identifier
        __typename
      }
      __typename
    }
    formtypes {
      studyid
      formtypeid
      identifier
      fields {
        name
        type
        required
        length
        pattern
        __typename
      }
      __typename
    }
    subjects {
      siteid
      studyid
      subjectid
      identifier
      status
      forms {
        subjectid
        formtypeid
        __typename
      }
      activities {
        activitytypeid
        subjectid
        comment
        startTime
        stopTime
        __typename
      }
      devices {
        deviceid
        position
        __typename
      }
      snapshot
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnStudiesUpdatesSubscriptionVariables,
  APITypes.OnStudiesUpdatesSubscription
>;
export const onSubjectsUpdates = /* GraphQL */ `subscription OnSubjectsUpdates($studyid: ID!) {
  onSubjectsUpdates(studyid: $studyid) {
    siteid
    studyid
    subjectid
    identifier
    status
    forms {
      subjectid
      formtypeid
      formtype {
        studyid
        formtypeid
        identifier
        __typename
      }
      fields {
        key
        value
        __typename
      }
      __typename
    }
    activities {
      activitytypeid
      subjectid
      comment
      startTime
      stopTime
      activitytype {
        activitytypeid
        description
        studyid
        __typename
      }
      referenceData {
        referencedataid
        activitytypeid
        siteid
        subjectid
        studyid
        fields
        filename
        persistedObjectName
        objectUrl
        status
        __typename
      }
      __typename
    }
    devices {
      deviceid
      position
      __typename
    }
    snapshot
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnSubjectsUpdatesSubscriptionVariables,
  APITypes.OnSubjectsUpdatesSubscription
>;
export const onSubjectUpdates = /* GraphQL */ `subscription OnSubjectUpdates($studyid: ID!, $subjectid: ID!) {
  onSubjectUpdates(studyid: $studyid, subjectid: $subjectid) {
    siteid
    studyid
    subjectid
    identifier
    status
    forms {
      subjectid
      formtypeid
      formtype {
        studyid
        formtypeid
        identifier
        __typename
      }
      fields {
        key
        value
        __typename
      }
      __typename
    }
    activities {
      activitytypeid
      subjectid
      comment
      startTime
      stopTime
      activitytype {
        activitytypeid
        description
        studyid
        __typename
      }
      referenceData {
        referencedataid
        activitytypeid
        siteid
        subjectid
        studyid
        fields
        filename
        persistedObjectName
        objectUrl
        status
        __typename
      }
      __typename
    }
    devices {
      deviceid
      position
      __typename
    }
    snapshot
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnSubjectUpdatesSubscriptionVariables,
  APITypes.OnSubjectUpdatesSubscription
>;
export const onReferenceDataUpdates = /* GraphQL */ `subscription OnReferenceDataUpdates($subjectid: ID!, $activitytypeid: ID!) {
  onReferenceDataUpdates(
    subjectid: $subjectid
    activitytypeid: $activitytypeid
  ) {
    referencedataid
    activitytypeid
    siteid
    subjectid
    studyid
    fields
    filename
    persistedObjectName
    objectUrl
    status
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnReferenceDataUpdatesSubscriptionVariables,
  APITypes.OnReferenceDataUpdatesSubscription
>;
